// Bootstrap JS
var bootstrap = require('bootstrap');

let tofeConnect = require( './tofeConnect' );

// Libs
let datepicker = require('./datepicker');

document.addEventListener('DOMContentLoaded', () => {
   // Datepicker
    const dateRangePickerEl = document.querySelector(".daterangepicker");
    if(dateRangePickerEl) {
        new datepicker().range('.daterangepicker');
    }

    const datepickerEl = document.querySelector(".datepicker");
    if(datepickerEl) {
        new datepicker().init('.datepicker');
    }

});
