var bootstrap = require('bootstrap');

var debugMode = false;
var impersonate = "";

function logToConsole(message) {
	if (debugMode) {
		console.log(message);
	}
}

async function fetchWithTimeout(resource, options = {}) {
	logToConsole('fetchWithTimeout: ' + resource);
	const { timeout = 8000 } = options;
	const headers = new Headers();
	logToConsole('Timeout set to: ' + timeout );
	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
	if (!(null == impersonate)) {
		if (impersonate.length == 32) {
			resource += '&I=' + impersonate;
		}
	}

	headers.append('pragma', 'no-cache');
	headers.append('cache-control', 'no-cache');

	const response = await fetch(resource, {
		...options,
		headers: headers,
		signal: controller.signal
	});
	clearTimeout(id);
	if (response.status === 401) {
		window.location.href = '/pages/authentication/login';
	}
	return response;
}

function getUrlParameter(param) {
	let result = "";
	const queryString = window.location.search;
	if (!((typeof queryString) == 'undefined')) {
		const urlParams = new URLSearchParams(queryString);
		result = urlParams.get(param);
	}
	return result;
}



const tofeReadyStateChange = (evt) => {

	if (document.readyState === 'complete') {
		impersonate = getUrlParameter("I");
		const accountListMessage = document.getElementById('account-list-message');
		const serviceUrlAccount = '/api/account.xsp';
		const serviceUrlUser = '/api/user.xsp';
		const accountListDataTable = 'accountListDataTable';
		const addAccountErrorLbl = 'addAccountErrorLbl';
		const accountListErrorLbl = 'account-list-error';
		const dialogShowAccount = 'modalShowAccount';
		const dialogDeleteAccount = 'modalDeleteAccount';
		const loadingHtml = '<div align="center"><img src="/img/loading.gif" /></div>';

		const elementDisabled = (elementId, newMode) => {
			const buttonElement = document.getElementById(elementId);
			if (!(buttonElement.disabled == newMode)) {
				buttonElement.disabled = newMode;
			}
		}
		const displayError = (errElementId, errStr) => {
			const errorLbl = document.getElementById(errElementId);
			logToConsole("DISPLAY: " + errElementId + ": " + typeof (errorLbl) + ": " + errorLbl);
			errorLbl.textContent = errStr;
			errorLbl.style.display = null;
			errorLbl.scrollIntoView();
		}
		/**
		 * Clears the error displayed
		 */
		const clearError = (errElementId) => {
			const errorLbl = document.getElementById(errElementId);
			logToConsole("CLEAR: " + errElementId + ": " + typeof (errorLbl) + ": " + errorLbl);
			errorLbl.textContent = "";
			errorLbl.style.display = "none";
		};

		/** 
		*	retrieves the account list from the service as Text. 
		*	@param {Event} evt
		*/
		const displayErrorJson = (errElementId, data) => {
			const errorMsgUser = data.error.userErrorMsg;
			const errorMsgTech = data.error.techErrorMsg;
			logToConsole("ERROR: " + errorMsgTech);
			displayError(errElementId, errorMsgUser);
		}

		const resetInputField = (elementId) => {
			const elementObj = document.getElementById(elementId);
			if (elementObj.type == 'checkbox') {
				elementObj.checked = false;
			} else {
				elementObj.value = "";
			}
		}

		const setElementTextContent = (elementId, value) => {
			logToConsole('setElementTextConent: ' + elementId + " with value " + typeof value);
			const elementObj = document.getElementById(elementId);
			elementObj.textContent = value;
		}

		const setElementInnerHtml = (elementId, value) => {
			logToConsole('setElementInnerHtml: ' + elementId + " with value " + typeof value);
			const elementObj = document.getElementById(elementId);
			elementObj.innerHTML = value;
		}

		const setInputValue = (elementId, value) =>{
			logToConsole('setInputValue: ' + elementId + " with value " + typeof value);
			const elementObj = document.getElementById(elementId);
			elementObj.value = value;
		}

		const getInputValue = (elementId) => {
			logToConsole('getInputValue: ' + elementId);
			const elementObj = document.getElementById(elementId);
			return elementObj.value;
		}

		const accountOpen = (evt) => {
			logToConsole('accountOpen');
			const target = evt.target;
			const targetParent = target.closest('tr');
			const accountId = targetParent.getAttribute('data-account-id');
			const accountType = targetParent.getAttribute('data-account-type');
			logToConsole("OPEN: " + accountId + " - " + accountType);
			restShowAccount(accountType, accountId);
			evt.preventDefault();
		};

		const accountDelete = (evt) => {
			logToConsole('accountDelete');
			const target = evt.target;
			const targetParent = target.closest('tr');
			const accountId = targetParent.getAttribute('data-account-id');
			const accountType = targetParent.getAttribute('data-account-type');
			logToConsole("DELETE: " + accountId + " - " + accountType);
			confirmDeleteAccount(accountType, accountId);
			evt.preventDefault();
		}

		const switchPaperlessState = (evt) => {
			logToConsole('switchPaperlessState');
			const target = evt.target;
			const targetParent = target.closest('tr');
			const accountId = targetParent.getAttribute('data-account-id');
			const accountType = targetParent.getAttribute('data-account-type');
			logToConsole("SWITCH-PAPERLESS: " + accountId + " - " + accountType);
			restAccountSwitchPaperless(evt, accountType, accountId);
		}

		const retrieveAccountList = () => {
			logToConsole('retrieveAccountList');
			accountListMessage.innerHTML = loadingHtml;
			accountListMessage.style.display = null;

			clearAccountListTable();
			fetchWithTimeout((serviceUrlAccount + '?ACTION=LIST&P1=ALL'), { timeout: 20000 })
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson('account-list-message', res);
					} else if (res.hasOwnProperty('data')) {
						let data = res.data;
						logToConsole('data[0]:', data[0]);
						renderAccountListTable(data);
					}
				})
				.catch((error) => {
					console.error('Error occurred in retrieveAccountList:', error);
					displayError('account-list-message', `Unable to retrieve accounts at this time, please try again later.`);
				});
		}

		const hideAccountListTable = (message) => {
			const tableElement = document.getElementById(accountListDataTable);
			tableElement.style.display = "none";
			accountListMessage.textContent = message;
			accountListMessage.style.display = null;
		}

		/**
		 * Removes all rows in the table displaying the data.
		 */
		const clearAccountListTable = () => {
			const tbody = document.getElementById(accountListDataTable).tBodies[0];
			while (tbody.rows.length > 0) {
				tbody.deleteRow(-1);
			}
		};

		const formatNumber = (inputNumber) => {
			let result = inputNumber;
			if (typeof (inputNumber) == "number") {
				result = inputNumber.toFixed(2);
			}
			return result;
		}

		const accountListTableInsertRow = (tbody, account) => {
			tr = tbody.insertRow();
			tr.setAttribute('data-account-id', account.accountId);
			tr.setAttribute('data-account-type', account.accountType);

			newCell = tr.insertCell(0);
			newCell.setAttribute('class', 'clickable');
			newText = document.createTextNode(account.accountType);
			newCell.appendChild(newText);
			newCell.addEventListener('click', accountOpen);

			newCell = tr.insertCell(1);
			newCell.setAttribute('class', 'clickable');
			newText = document.createTextNode(account.accountId);
			newCell.appendChild(newText);
			newCell.addEventListener('click', accountOpen);

			newCell = tr.insertCell(2);
			newCell.setAttribute('class', 'clickable');
			newText = document.createTextNode(account.accountDesc);
			newCell.appendChild(newText);
			newCell.addEventListener('click', accountOpen);

			newCell = tr.insertCell(3);
			newText = document.createTextNode(formatNumber(account.accountBalance));
			newCell.setAttribute('class', 'text-end clickable');
			newCell.appendChild(newText);
			newCell.addEventListener('click', accountOpen);

			newCell = tr.insertCell(4);
			newText = document.createTextNode(formatNumber(account.accountPastDue));
			newCell.setAttribute('class', 'text-end clickable');
			newCell.appendChild(newText);
			newCell.addEventListener('click', accountOpen);

			newCell = tr.insertCell(5);
			newCell.setAttribute( 'class', 'text-end' );
			newText = document.createElement( 'i' );
			if(account.accountPap == 'Y'){
				newText.setAttribute('class', 'bi bi-check-circle');	
				newText.setAttribute('style', 'font-size:1.2rem;color:green');	
			} else {
				newText.setAttribute('class', 'bi bi-x-circle');	
				newText.setAttribute('style', 'font-size:1.2rem;color:red');	
			}						
			newCell.appendChild(newText);			

			newCell = tr.insertCell(6);
			newCell.setAttribute('class', 'paperless text-end');
			newHtmlDiv = document.createElement('div');
			newHtmlDiv.setAttribute('class', 'form-check form-switch');	
			newHtmlDiv.setAttribute('align', 'right');
			newInput = document.createElement('input');
			newInput.setAttribute('class', 'form-check-input');
			newInput.setAttribute('type', 'checkbox');			
			if (account.paperless == 'true') {
				newInput.checked = true;
			}
			newInput.addEventListener('click', switchPaperlessState);
			newHtmlDiv.appendChild(newInput);
			newCell.appendChild(newHtmlDiv);

			newCell = tr.insertCell(7);

			if( account.payment ){
				newText = document.createElement( 'a');				
				newText.setAttribute('type', 'button');
				newText.setAttribute('class', 'btn btn-sm btn-neutral');
				newText.setAttribute('style', 'margin-right:5px');				
				newText.setAttribute( 'href', 'https://www.forterie.ca/resource/eservices.xsp?S1=' + account.accountType + '&S2=' + account.accountId + '&S3=' + account.paymentGroup );
				newText.setAttribute( 'target', '_blank');
				newText.innerHTML = '<i class=\"bi bi-credit-card\" style=\"padding-right:5px;color:green\"></i>Pay';
				newCell.appendChild(newText);
			}

			newText = document.createElement('button');
			newText.setAttribute('type', 'button');
			newText.setAttribute('class', 'btn btn-sm btn-neutral');
			newText.setAttribute('style', 'margin-right:5px');
			newText.innerHTML = '<i class=\"bi bi-file-pdf\" style=\"padding-right:5px;color:red\"></i>Bills';

			newText.addEventListener('click', accountOpen);
			newCell.appendChild(newText);

			newText = document.createElement('button');
			newText.setAttribute('class', 'btn btn-sm btn-square btn-neutral text-danger-hover');
			newText.setAttribute('type', 'button');
			newText.innerHTML = '<i class=\"bi bi-trash\"></i>';
			newText.addEventListener('click', accountDelete);

			newCell.appendChild(newText);
			newCell.setAttribute('class', 'text-end');
		}

		const deleteAccountListRow = (accountType, accountId) => {
			const tableBody = document.getElementById(accountListDataTable);
			for (tablerow of tableBody.rows) {
				if (tablerow.getAttribute('data-account-id') == accountId) {
					if (tablerow.getAttribute('data-account-type') == accountType) {
						tableBody.deleteRow(tablerow.rowIndex);
						logToConsole("Deleted row from table: " + accountType + " - " + accountId);
						if (tableBody.rows.length == 1) {
							logToConsole("Indicate no accounts are found");
							hideAccountListTable("No accounts found");
						}
					}
				}
			}
		}

		const sortDataTable = (elementId, col, dir) => {
			const table = document.getElementById(elementId);
			let switching = true;
			let switchCount = 0;

			while (switching) {
				switching = false;
				let rows = table.rows;
				let shouldSwitch = false;
				for (i = 1; i < (rows.length - 1); i++) {
					let x = rows[i].getElementsByTagName('TD')[col];
					let y = rows[i + 1].getElementsByTagName('TD')[col];
					if (dir == 'asc') {
						if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
							shouldSwitch = true;
							break;
						}
					} else if (dir == 'desc') {
						if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
							shouldSwitch = true;
							break;
						}
					}
				}
				if (shouldSwitch) {
					rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
					switching = true;
					switchCount++;
				} else {
					if (switchCount == 0 && dir == 'asc') {
						dir = 'desc';
						switching = true;
					}
				}
			}
			const tableColumn = table.rows[0].cells[col];
			tableColumn.setAttribute('sort', dir);
		}
		/**
		 * Populates table element with data retrieved from service.
		 * 	@param {array} data
		 */
		const renderAccountListTable = (data) => {
			const tableElement = document.getElementById(accountListDataTable);
			const tbody = tableElement.tBodies[0]; let tr; let newCell; let newText;
			tableElement.style.display = 'none';

			let count = 0;

			for (account of data) {
				accountListTableInsertRow(tbody, account);
				count++;
			}

			if (count > 0) {
				accountListMessage.style.display = 'none';
				accountListMessage.innerHTML = '';
				tableElement.style.display = null;
				sortDataTable(accountListDataTable, 1, 'asc');
			} else {
				accountListMessage.textContent = "No accounts found";
			}
		};

		/**
		 * Over-simplfied input validation.  Accept non-null, non-whitespace string values.
		 * @param {string} valStr
		 * @returns {boolean} true if valid, false if invalid.
		 */
		const stringInputValIsValid = (valStr) => {
			if (null === valStr || typeof valStr !== 'string' || valStr.match(/^ *$/) !== null)
				return false;
			return true;
		};
		const restAccountCreate = (account) => {
			const serviceUrlParamed = `${serviceUrlAccount}?ACTION=ADD&P3=${account.accountCode}&P2=${account.accountId}&P1=${account.accountType}&P4=${account.accountConfirm}`;

			logToConsole('restAccountCreate(): serviceUrlParamed=', serviceUrlParamed);
			clearError(addAccountErrorLbl);
			fetchWithTimeout(serviceUrlParamed, { timeout: 10000 })
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson(addAccountErrorLbl, res);
					} else if (res.hasOwnProperty('data')) {
						let openedCanvasCloseBtn = document.getElementById('offcanvasCloseBtn');
						openedCanvasCloseBtn.click();
						const tableElement = document.getElementById(accountListDataTable);
						const tbody = tableElement.tBodies[0];
						accountListTableInsertRow(tbody, res.data[0]);
						// Reset the add acount form
						resetInputField('addAccountId');
						resetInputField('addAccountCode');
						resetInputField('addAccountConfirm');

						if (tableElement.style.display == "none") {
							accountListMessage.style.display = "none";
							accountListMessage.innerHTML = "";
							tableElement.style.display = null;
						};
					}
				})
				.catch((error) => {
					console.error('Error occurred in restAccountCreate:', error);
					displayError(addAccountErrorLbl, `Error occurred: ${error}`);
				});
		};
		const restAccountUpdate = (account) => {
			const serviceUrlParamed = `${serviceUrlAccount}?ACTION=UPDATE&ACCOUNT_CODE=${account.accountCode}&ACCOUNT_NAME=${account.accountName}`;

			logToConsole('restAccountUpdate(): serviceUrlParamed=', serviceUrlParamed);
			clearError(addAccountErrorLbl);
			fetch(serviceUrlParamed)
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson(addAccountErrorLbl, res);
					} else if (res.hasOwnProperty('data')) {
						// TODO - Update table
						// const tbody = accountListDataTable.tBodies[0];
						// accountListTableInsertRow( tbody, res );
					}
				})
				.catch((error) => {
					console.error('Error occurred in restAccountCreate:', error);
					displayError(addAccountErrorLbl, `Error occurred: ${error}`);
				});
		};
		/**
		 * Post provided account data to remote service.
		 * @param {object} account
		 * 
		 */
		async function postAccountData(account) {

			//		const encodedViewId = encodeURIComponent(viewId);
			//		const response = await fetch(`${serviceUrl}?$$viewid==${encodedViewId}` , 
			const response = await fetch(serviceUrlAccount,
				{
					method: 'POST',
					mode: 'same-origin',
					cache: 'no-cache',
					credentials: 'same-origin',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(account)
				}
			);

			const jsonData = response.json();
			// TODO - Implement POST
		}

		const restShowAccount = (accountType, accountId) => {
			const modalElement = document.getElementById(dialogShowAccount);
			const dialogModal = new bootstrap.Modal(modalElement);
			setElementTextContent('showAccountBillHeader', accountType.toLowerCase() + ' Bills for ' + accountId);
			setElementInnerHtml('showAccountBillData', loadingHtml);
			dialogModal.show();
			const serviceUrlParamed = `${serviceUrlAccount}?ACTION=FETCH-BILLS&P1=${accountType}&P2=${accountId}`;

			logToConsole('restShowAccount(): serviceUrlParamed=', serviceUrlParamed);
			fetchWithTimeout(serviceUrlParamed, { timeout: 10000 })
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson('showAccountBillData', res);
					} else if (res.hasOwnProperty('data')) {
						let bills = "";
						let count = 0;
						try {
							for (bill of res.data) {
								bills += '<a href=\"' + bill.billUrl + '\" target=\"_blank\">' + bill.billDesc + '</a>';								
								bills += '<br />';
								count++;
							}
						} catch (err) {
							setElementInnerHtml('showAccountBillData'.err.message);
						}

						if (count > 0) {
							setElementInnerHtml('showAccountBillData', bills);
						} else {
							setElementInnerHtml('showAccountBillData', "No bills found");
						}
					}
				})
				.catch((error) => {
					console.error('Error occurred in restShowAccount:', error);
					setElementInnerHtml('showAccountBillData', 'Unable to lookup bills on account');
				});
		}

		const confirmDeleteAccount = (accountType, accountId) => {
			setElementInnerHtml('deleteAccountError', '');
			const modalElement = document.getElementById(dialogDeleteAccount);
			const dialogModal = new bootstrap.Modal(modalElement);
			const modalFooter = document.getElementById('deleteAccountFooter');
			modalFooter.innerHTML = "";

			newButton = document.createElement('button');
			newButton.setAttribute('type', 'button');
			newButton.setAttribute('class', 'btn btn-sm btn-neutral');
			newButton.setAttribute('data-bs-dismiss', 'modal');
			newButton.textContent = 'Cancel';
			modalFooter.appendChild(newButton);

			newButton = document.createElement('button');
			newButton.setAttribute('id', 'deleteAccountConfirmBtn');
			newButton.setAttribute('type', 'button');
			newButton.setAttribute('class', 'btn btn-sm btn-danger');
			newButton.setAttribute('data-bs-dismiss', 'modal');
			newButton.setAttribute('data-account-id', accountId);
			newButton.setAttribute('data-account-type', accountType);
			newButton.textContent = 'Delete';
			newButton.addEventListener('click', restDeleteAccount);
			modalFooter.appendChild(newButton);

			setElementTextContent('deleteAccountHeader', 'Confirm delete from profile');
			setElementInnerHtml('deleteAccountData', accountType.toLowerCase() + '  account  ' + accountId);
			dialogModal.show();
		}

		const restDeleteAccount = (evt) => {
			const target = evt.target;
			const accountId = target.getAttribute('data-account-id');
			const accountType = target.getAttribute('data-account-type');
			const serviceUrlParamed = `${serviceUrlAccount}?ACTION=DELETE&P1=${accountType}&P2=${accountId}`;

			logToConsole('restShowAccount(): serviceUrlParamed=', serviceUrlParamed);
			fetchWithTimeout(serviceUrlParamed, { timeout: 10000 })
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson('deleteAccountError', res);
					} else if (res.hasOwnProperty('data')) {
						if (!(null == res.data[0].accountId)) {
							deleteAccountListRow(accountType, accountId);
							logToConsole("Deleted from profile: " + res.data[0].accountId);
						}
					}
				})
				.catch((error) => {
					console.error('Error occurred in restDeleteAccount:', error);
					setElementInnerHtml('showAccountBillData', 'Unable to remove account from your profile');
				});

		}

		const restAccountSwitchPaperless = (evt, accountType, accountId) => {
			const target = evt.target;
			let isActive = target.checked;
			target.disabled = true;
			if ((null == isActive)) {
				isActive = false;
			}
			logToConsole("Switching to: " + isActive + ' for ' + accountType + '-' + accountId);
			const serviceUrlParamed = `${serviceUrlAccount}?ACTION=PAPERLESS&P1=${accountType}&P2=${accountId}&P3=${isActive}`;

			logToConsole('restAccountSwitchPaperless(): serviceUrlParamed=' + serviceUrlParamed);
			clearError('account-list-message');
			fetchWithTimeout(serviceUrlParamed, { timeout: 10000 })
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson('account-list-message', res);
						if (isActive) {
							target.checked = false;
						} else {
							target.checked = true;
						}
						evt.preventDefault();
					} else if (res.hasOwnProperty('data')) {
						if (!(null == res.data[0].accountId)) {
							logToConsole("Switched paperless state on profile: " + res.data[0].accountId);
						}
					}
				})
				.catch((error) => {
					console.error('Error occurred in restAccountSwitchPaperless:', error);
					displayError('account-list-message', 'Unable to switch paperless status on account ' + accountId);
					evt.preventDefault();
					if (isActive) {
						target.checked = false;
					} else {
						target.checked = true;
					}
				});
			target.disabled = false;
		}

		const restRegistrationUpdate = (evt) => {
			clearError( 'updateRegistrationError' );
			const target = evt.target;
			const firstName = getInputValue( 'updateRegistrationFirstName' );
			const lastName = getInputValue( 'updateRegistrationLastName' );			
			const serviceUrlParamed = `${serviceUrlUser}?ACTION=UPDATE&P1=${firstName}&P2=${lastName}`;

			logToConsole('restRegistrationUpdate(): serviceUrlParamed=', serviceUrlParamed);
			fetchWithTimeout(serviceUrlParamed, { timeout: 10000 })
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson('updateRegistrationError', res);
					} else if (res.hasOwnProperty('data')) {
						if (!(null == res.data[0].nameFull)) {
							fullname = res.data[0].nameFull;						
							setElementTextContent('userProfileName', fullname);						
							setElementTextContent('userWelcome', ('Hi ' + fullname));							
						}
						let updateRegistationCloseBtn = document.getElementById('updateRegistationCloseBtn');
						updateRegistationCloseBtn.click();
					}
				})
				.catch((error) => {
					console.error('Error occurred in restRegistrationUpdate:', error);
					setElementInnerHtml('updateRegistrationError', 'Unable to update your profile');
				});
			evt.preventDefault;
		}

		/**
		 * Button click event for addAccountBtn
		 * @param {Event} evt
		 */
		const addAccountBtnClick = (evt) => {
			clearError(addAccountErrorLbl);
			logToConsole('addAccountBtnClick');
			elementDisabled('addAccountBtn', true);
			let account = null;
			const accountTypeInput = document.getElementById('addAccountType');
			const accountCodeInput = document.getElementById('addAccountCode');
			const accountIdInput = document.getElementById('addAccountId');
			const accountConfirmInput = document.getElementById('addAccountConfirm');
			const accountType = accountTypeInput.value;
			const accountCode = accountCodeInput.value;
			const accountId = accountIdInput.value;
			let accountConfirm = "false";
			if (accountConfirmInput.checked) {
				accountConfirm = "true";
			};
			logToConsole(`\taccountCode:[${accountCode}],accountId:[${accountId}]`);
			if (!stringInputValIsValid(accountCode)) {
				displayError(addAccountErrorLbl, 'Invalid Access Code Value');
				elementDisabled('addAccountBtn', false);
				return;
			}
			if (!stringInputValIsValid(accountId)) {
				displayError(addAccountErrorLbl, 'Invalid Account Number');
				elementDisabled('addAccountBtn', false);
				return;
			}
			account = { accountCode: accountCode, accountId: accountId, accountType: accountType, accountConfirm: accountConfirm };

			logToConsole('accessCode and accountId passed basic validation');
			restAccountCreate(account);
			elementDisabled('addAccountBtn', false);
			evt.preventDefault();
		};

		const updateAccountBtnClick = (evt) => {
			clearError(addAccountErrorLbl);
			logToConsole('updateAccountBtnClick');
			let account = null;
			const accountCodeInput = document.getElementById('accountCode');
			const accountNameInput = document.getElementById('accountName');
			const accountCode = accountCodeInput.value;
			const accountName = accountNameInput.value;
			logToConsole(`\taccountCode:[${accountCode}],accountName:[${accountName}]`);
			if (!stringInputValIsValid(accountCode)) {
				displayError(addAccountErrorLbl, 'Invalid Access Code Value');
				return;
			}
			if (!stringInputValIsValid(accountName)) {
				displayError(addAccountErrorLbl, 'Invalid Account Name Value');
				return;
			}
			account = { accountCode: accountCode, accountName: accountName };

			logToConsole('accountCode and accountName are good');
			restAccountUpdate(account)
			evt.preventDefault();
		};

		const updateRegistrationBtnClick = (evt) => {			
			logToConsole("updateRegistrationBtnClick(): Show Dialog");
			const modalElement = document.getElementById('modalUpdateRegistration');
			const dialogModal = new bootstrap.Modal(modalElement);			
			dialogModal.show();
			evt.preventDefault();
		}

		const clearAccountList = () => {
			logToConsole("Clearing out account list table");
			const tableElement = document.getElementById(accountListDataTable);
			const tbody = tableElement.tBodies[0];
			while (tbody.rows.length > 0) {
				tbody.deleteRow(-1);
			}
		}
		const userAccountDisplay = () => {
			logToConsole('updateAccountDisplay');
			let fullname = "Unknown";
			let emailAddress = "";
			const serviceUrlParamed = serviceUrlUser + '?ACTION=PROFILE';
			fetchWithTimeout(serviceUrlParamed, { timeout: 10000 })
				.then(response => response.json())
				.then((res) => {
					logToConsole(res);
					if (res.hasOwnProperty('error')) {
						displayErrorJson(accountListErrorLbl, res);
					} else if (res.hasOwnProperty('data')) {
						let fullname = res.data[0].nameFull;
						let firstname = res.data[0].nameFirst;
						let lastname = res.data[0].nameLast;
						let emailAddress = res.data[0].emailAddress;
						setElementTextContent('userProfileName', fullname);
						setElementTextContent('userProfileEmail', emailAddress);
						setElementTextContent('userWelcome', ('Hi ' + fullname));
						setInputValue( 'updateRegistrationFirstName', firstname );
						setInputValue( 'updateRegistrationLastName', lastname );
					}
				})
				.catch((error) => {
					console.error('Error occurred in userAccountDisplay:', error);
					displayError(accountListErrorLbl, `Error occurred: ${error}`);
				});

		}

		const sortTableColumnClick = (evt) => {
			const tableElement = evt.target.closest('table');
			const tableColumn = evt.target.cellIndex;
			const tableSort = evt.target.getAttribute('sort');
			let sortMethod = 'asc';
			let currentSort = 'asc';
			if (!((typeof tableSort) == 'undefined')) {
				currentSort = tableSort;
			}
			if (currentSort == 'asc') {
				sortMethod = 'desc';
			}
			logToConsole(tableElement.getAttribute('ID') + " - " + tableColumn + " - " + sortMethod);
			sortDataTable(tableElement.getAttribute('ID'), tableColumn, sortMethod);
		}

		const initDataTableColumnSort = (elementId) => {
			const table = document.getElementById(elementId);
			let rows = table.rows;
			let cells = rows[0].cells;
			let cellClass = '';
			try {
				for (cell of cells) {
					let cellLength = cell.textContent.length;
					if (!((typeof cellLength) == 'undefined')) {
						if (cellLength > 0) {
							cellClass = cell.getAttribute('sortable');
							if (!(null == cellClass)) {
								cell.addEventListener('click', sortTableColumnClick);
								cell.style.cursor = 'pointer';
							}
						}
					}
				}
			} catch (error) {
				console.error('Error occurred in initDataTableColumnSort: ', error);
			}


		}

		const initButtons = () => {
			const addAccountBtn = document.getElementById('addAccountBtn');
			addAccountBtn.addEventListener('click', addAccountBtnClick);

			const updateRegistrationBtnMobile = document.getElementById('updateRegistrationBtnMobile');
			updateRegistrationBtnMobile.addEventListener( 'click', updateRegistrationBtnClick );

			const updateRegistrationBtnDesktop = document.getElementById('updateRegistrationBtnDesktop');
			updateRegistrationBtnDesktop.addEventListener( 'click', updateRegistrationBtnClick );

			const updateRegistrationBtn = document.getElementById( 'updateRegistrationBtn' );
			updateRegistrationBtn.addEventListener( 'click', restRegistrationUpdate );
		}

		const postDocumentLoad = () => {
			console.log("myFE - Activating");
			userAccountDisplay();
			retrieveAccountList();
			initDataTableColumnSort(accountListDataTable);
			initButtons();
			console.log("myFE - At your service");
		};

		postDocumentLoad();
	}
};

// window.addEventListener('load', (event) => {
document.addEventListener('readystatechange', tofeReadyStateChange);